import React, { useState } from 'react';
import '../css/Addproduct.css'

const AddProduct = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const [selectedImage, setSelectedImage] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [selectedOption, setSelectedOption] = useState("Select Product Category");
  const [description, setDescription] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [productDetails, setProductDetails] = useState({
    name: "",
    image: "",
    category: "",
    new_price: "",
    old_price: "",
    description: "",
    productsonoffer: false
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setProductDetails({ ...productDetails, image: file });
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option.text);
    setProductDetails({ ...productDetails, category: option.text });
    setDropdownOpen(false);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    setProductDetails({ ...productDetails, description: e.target.value });
  };

  const changeHandler = (e) => {
    setProductDetails({ ...productDetails, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    setProductDetails({ ...productDetails, productsonoffer: e.target.checked });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsFormSubmitted(true);

    if (description && selectedOption !== "Select Product Category") {
      await Add_Product();
      console.log("Form submitted");
    }
  };

  const Add_Product = async () => {
    console.log(productDetails);
    let responseData;
    let product = productDetails;

    let formData = new FormData();
    formData.append('product', productDetails.image);

    await fetch(`${BACKEND_URL}/upload`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: formData,
    }).then((resp) => resp.json()).then((data) => { responseData = data });

    if (responseData.success) {
      product.image = responseData.image_url;
      console.log(product);
      await fetch(`${BACKEND_URL}/addproduct`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(product),
      }).then((resp) => resp.json()).then((data) => {
        data.success ? alert("product Added") : alert("Failed")
      })
    }
  };

  const menuItems = [
    { id: "#Angels", text: "Angels" },
    { id: "#Bracelet", text: "Bracelet" },
    { id: "#Candles", text: "Candles" },
    { id: "#Clusters", text: "Clusters" },
    { id: "#Ganesh", text: "Ganesh" },
    { id: "#Stone Statue", text: "Stone Statue" },
    { id: "#Home Decor", text: "Home Decor" },
    { id: "#Jaap Mala", text: "Jaap Mala" },
    { id: "#Stones", text: "Stones" },
    { id: "#Rings", text: "Rings" },
    { id: "#Pendulum", text: "Pendulum" },
    { id: "#Pyramid", text: "Pyramid" },
    { id: "#Pendant", text: "Pendant" },
    { id: "#Pencil Wand", text: "Pencil Wand" },
    { id: "#Raw Stones", text: "Raw Stones" },
    { id: "#Tumble Stone", text: "Tumble Stone" },
    { id: "#Tree", text: "Tree" },
    { id: "#Ball", text: "Ball" },
    { id: "#Rudraksh", text: "Rudraksh" },
    { id: "#Vastu Product", text: "Vastu Product" },
    { id: "#Wish Box", text: "Wish Box" },
    { id: "#Beauty Tools", text: "Beauty Tools" },
    { id: "#Crystal", text: "Crystal" },
    { id: "#Gemstone", text: "Gemstone" },
    { id: "#Crystal Bracelet", text: "Crystal Bracelet" },
    { id: "#Crystal Pyramid", text: "Crystal Pyramid" },
    { id: "#Crystal Tree", text: "Crystal Tree" },
    { id: "#Heart", text: "Heart" }
  ];

  const filteredItems = menuItems.filter(item =>
    item.text.toUpperCase().includes(filter.toUpperCase())
  );

  return (
    <div className="container">
      <div className="row">
        <h3 className='text-center'>Add Product</h3>
        <div className="col-md-12">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="productName" className='py-3'>Product Name</label>
              <input
                type="text"
                name="name"
                value={productDetails.name}
                onChange={changeHandler}
                className="form-control"
                id="productName"
                placeholder="Enter Product Name"
                autoComplete="off"
                required
              />
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="price" className='py-3'>Price</label>
                  <input
                    type="number"
                    name="old_price"
                    value={productDetails.old_price}
                    onChange={changeHandler}
                    className="form-control"
                    id="price"
                    placeholder="Enter Price"
                    autoComplete="off"
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="offerPrice" className='py-3'>Offer Price</label>
                  <input
                    type="number"
                    name="new_price"
                    value={productDetails.new_price}
                    onChange={changeHandler}
                    className="form-control"
                    id="offerPrice"
                    placeholder="Enter Offer Price"
                    autoComplete="off"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="productDescription" className='py-3'>Product Description</label>
                <textarea
                  className="form-control"
                  id="productDescription"
                  rows="3"
                  name="description"
                  value={productDetails.description}
                  onChange={handleDescriptionChange}
                  required
                ></textarea>
              </div>
            </div>
            <div className="col-md-6 py-3">
              <div className="dropdown">
                <button
                  type="button"
                  onClick={toggleDropdown}
                  className="btn btn-success dropdown-toggle"
                  required
                >
                  {selectedOption}
                </button>
                <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`}>
                  <input
                    type="text"
                    placeholder="Search.."
                    className="form-control"
                    value={filter}
                    onChange={handleFilterChange}
                  />
                  {filteredItems.map(item => (
                    <a key={item.id} className="dropdown-item" href="#" onClick={() => handleOptionSelect(item)}>
                      {item.text}
                    </a>
                  ))}
                </div>
              </div>
              {isFormSubmitted && selectedOption === "Select Product Category" && <div className="invalid-feedback d-block">Please select a category.</div>}
            </div>

            <div className="col-md-12 py-3">
              <div>
                {selectedImage && (
                  <div>
                    <img src={selectedImage} alt="Uploaded" style={{ maxWidth: '250px', marginTop: '20px', marginBottom: '20px' }} />
                  </div>
                )}
                <p>Select image</p>
                <input type="file" onChange={handleImageChange} accept="image/*" className='file_input' autoComplete="off" required />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={productDetails.productsonoffer}
                  onChange={handleCheckboxChange}
                  id="defaultCheck1"
                  style={{
                    border: '2px solid black',
                    width: '16px',
                    height: '16px',
                    boxSizing: 'border-box',
                  }}
                />
                <label className="form-check-label" htmlFor="defaultCheck1">
                  Products On Offer
                </label>
              </div>
            </div>

            <button type="submit" className='add_btn'>Add Product</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddProduct;
