import React, { useState } from 'react';
import { Outlet, Link } from "react-router-dom";
import '../css/Layout.css';

const Layout = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`layout ${isOpen ? 'sidebar-open' : ''}`}>
            <nav className={`sidebar ${isOpen ? 'open' : ''}`}>
                <ul>
                    <li>
                        <Link to="/adminorder" className='sidebar_link' onClick={toggleSidebar}><p>Orders</p></Link>
                    </li>
                    <li>
                        <Link to="/addproduct" className='sidebar_link' onClick={toggleSidebar}><p>Add Product</p></Link>
                    </li>
                    <li>
                        <Link to="/productlist" className='sidebar_link' onClick={toggleSidebar}><p>Product List</p></Link>
                    </li>
                </ul>
            </nav>
            <div className="content">
                <button className="menu-toggle text-dark" onClick={toggleSidebar}>
                    {isOpen ? 'Close Menu' : 'Open Menu'}
                </button>
                <Outlet />
            </div>
        </div>
    );
}

export default Layout;
