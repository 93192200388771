// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import '../css/Adminorder.css';

// const Adminorder = () => {
//   const [addresses, setAddresses] = useState([]); 
//   const [dropdownOpen, setDropdownOpen] = useState(false);
//   const [filter, setFilter] = useState("");
//   const [selectedOption, setSelectedOption] = useState("Select Status");
//   const [orders, setOrders] = useState([]);

//   const toggleDropdown = () => {
//     setDropdownOpen(!dropdownOpen);
//   };

//   useEffect(() => {
   
//     const fetchAddresses = async () => {
//       try {
//         const res = await axios.get('${BACKEND_URL}/getAddresses');
//         setAddresses(res.data);
//       } catch (error) {
//         console.error('Error fetching addresses:', error);
//       }
//     };

//     fetchAddresses();
//   }, []);

//   const handleFilterChange = (event) => {
//     setFilter(event.target.value);
//   };

//   const handleOptionSelect = (option) => {
//     setSelectedOption(option.text);
//     setDropdownOpen(false);
//   };

//   const menuItems = [
//     { id: "#Order processing", text: "Order processing" },
//     { id: "#Out for delivery", text: "Out for delivery" },
//     { id: "#Delivered", text: "Candles" }
//   ];

//   const filteredItems = menuItems.filter(item =>
//     item.text.toUpperCase().includes(filter.toUpperCase())
//   );

//   return (
//     <div className="container">
//       <h3 className='text-center pt-3 pb-5'>Order Page</h3>
//       <div className="row m-3">
//         {addresses.map((address, index) => (
//           <div key={index} className="col-md-12 order_box">
//             <div className="row my-3">
//               <div className="col-md-1 d-flex align-items-center">
//                 <img width="48" height="48" src="https://img.icons8.com/fluency/48/product.png" alt="product" />
//               </div>
//               <div className="col-md-5 d-flex flex-column">
//                 <h6>Product: {address.cartItems.map(item => `${item.name} * ${item.quantity}`).join(', ')}</h6>
//                 <h6>Name: {address.firstName} {address.lastName}</h6>
//                 <h6>Address: {address.address}, {address.city}, {address.state} {address.pincode}</h6>
//                 <h5>Number: {address.phoneNumber}</h5>
//               </div>
//               <div className="col-md-2 d-flex align-items-center">
//                 <div className='d-flex justify-content-center align-items-center'>
//                   <p className='mb-0'>Item: </p>
//                   <p className='mb-0 font-weight-bold'>&nbsp;{address.cartItems.reduce((total, item) => total + item.quantity, 0)}</p>
//                 </div>
//               </div>
//               <div className="col-md-2 d-flex align-items-center">
//                 <div className='d-flex justify-content-center align-items-center'>
//                   <p className='mb-0 font-weight-bold'>&nbsp;₹ {address.cartItems.reduce((total, item) => total + item.price, 0)}</p>
//                 </div>
//               </div>
//               <div className="col-md-2 d-flex align-items-center">
//                 <div className="col-md-6 py-3">
//                   <div className="dropdown">
//                     <button
//                       type="button"
//                       onClick={toggleDropdown}
//                       className="btn btn-success dropdown-toggle"
//                       required
//                     >
//                       {selectedOption}
//                     </button>
//                     <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`}>
//                       <input
//                         type="text"
//                         placeholder="Search.."
//                         className="form-control"
//                         value={filter}
//                         onChange={handleFilterChange}
//                       />
//                       {filteredItems.map(item => (
//                         <a key={item.id} className="dropdown-item" href="#" onClick={() => handleOptionSelect(item)}>
//                           {item.text}
//                         </a>
//                       ))}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
           
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Adminorder;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import '../css/Adminorder.css';

// const Adminorder = () => {
//   const [addresses, setAddresses] = useState([]); 
//   const [dropdownOpen, setDropdownOpen] = useState({});
//   const [filter, setFilter] = useState("");
//   const [selectedOptions, setSelectedOptions] = useState({});

//   const statusHandler = async (event,orderId) =>{
//           console.log(event,orderId);
//           const response =await axios.post('${BACKEND_URL}/orderstatus',{
//             orderId,
//             status:event.target.value
//           })
//           if (response.data.success) {
//             await fetchAddresses();
//           }
//   }

//   useEffect(() => {
//     const fetchAddresses = async () => {
//       try {
//         const res = await axios.get('${BACKEND_URL}/admingetAddresses');
        
       
//         const sortedAddresses = res.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
//         setAddresses(sortedAddresses);
//       } catch (error) {
//         console.error('Error fetching addresses:', error);
//       }
//     };

//     fetchAddresses();
//   }, []);
 
//   const toggleDropdown = (index) => {
//     setDropdownOpen(prevState => ({
//       ...prevState,
//       [index]: !prevState[index]
//     }));
//   };

//   const handleFilterChange = (event) => {
//     setFilter(event.target.value);
//   };

//   const handleOptionSelect = (index, option) => {
//     setSelectedOptions(prevState => ({
//       ...prevState,
//       [index]: option.text
//     }));
//     setDropdownOpen(prevState => ({
//       ...prevState,
//       [index]: false
//     }));
//   };

//   const menuItems = [
//     { id: "#Order processing", text: "Order processing" },
//     { id: "#Out for delivery", text: "Out for delivery" },
//     { id: "#Delivered", text: "Delivered" }
//   ];

//   const filteredItems = menuItems.filter(item =>
//     item.text.toUpperCase().includes(filter.toUpperCase())
//   );

//   return (
//     <div className="container">
//       <h3 className='text-center pt-3 pb-5'>Order Page</h3>
//       <div className="row m-3">
//         {addresses.map((address, index) => (
//           <div key={index} className="col-md-12 order_box">
//             <div className="row my-3">
//               <div className="col-md-1 d-flex align-items-center">
//                 <img width="48" height="48" src="https://img.icons8.com/fluency/48/product.png" alt="product" />
//               </div>
//               <div className="col-md-5 d-flex flex-column">
//                 <h6>Product: {address.cartItems.map(item => `${item.name} * ${item.quantity}`).join(', ')}</h6>
//                 <h6>Name: {address.firstName} {address.lastName}</h6>
//                 <h6>Address: {address.address}, {address.city}, {address.state} {address.pincode}</h6>
//                 <h5>Number: {address.phoneNumber}</h5>
//                 <h6>Order Date: {new Date(address.createdAt).toLocaleString()}</h6>
//               </div>
//               <div className="col-md-2 d-flex align-items-center">
//                 <div className='d-flex justify-content-center align-items-center'>
//                   <p className='mb-0'>Item: </p>
//                   <p className='mb-0 font-weight-bold'>&nbsp;{address.cartItems.reduce((total, item) => total + item.quantity, 0)}</p>
//                 </div>
//               </div>
//               <div className="col-md-2 d-flex align-items-center">
//                 <div className='d-flex justify-content-center align-items-center'>
//                   <p className='mb-0 font-weight-bold'>&nbsp;₹ {address.cartItems.reduce((total, item) => total + item.price, 0)}</p>
//                 </div>
//               </div>
//               <div className="col-md-2 d-flex align-items-center">
//                 <div className="col-md-6 py-3">
//                   <select onChange={(event)=>statusHandler(event,address._id)} value={address.status}>
//                     <option value="Order Processing">Order Processing</option>
//                     <option value="Out for delivery">Out for delivery</option>
//                     <option value="Delivered">Delivered</option>
//                   </select>
//                 </div>
//               </div>
//             </div>
           
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Adminorder;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/Adminorder.css';

const Adminorder = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const [addresses, setAddresses] = useState([]); 
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [filter, setFilter] = useState("");
  const [selectedOptions, setSelectedOptions] = useState({});

  const fetchAddresses = async () => {
    try {
      const res = await axios.get(`${BACKEND_URL}/admingetAddresses`);
      const sortedAddresses = res.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setAddresses(sortedAddresses);
    } catch (error) {
      console.error('Error fetching addresses:', error);
    }
  };

  const statusHandler = async (event, orderId) => {
    try {
      const response = await axios.post(`${BACKEND_URL}/orderstatus`, {
        orderId,
        status: event.target.value,
      });
      if (response.data.success) {
        await fetchAddresses();  // Refresh the data after status update
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  useEffect(() => {
    fetchAddresses();
  }, []);
 
  const toggleDropdown = (index) => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleOptionSelect = (index, option) => {
    setSelectedOptions(prevState => ({
      ...prevState,
      [index]: option.text
    }));
    setDropdownOpen(prevState => ({
      ...prevState,
      [index]: false
    }));
  };

  const menuItems = [
    { id: "#Order processing", text: "Order processing" },
    { id: "#Out for delivery", text: "Out for delivery" },
    { id: "#Delivered", text: "Delivered" }
  ];

  const filteredItems = menuItems.filter(item =>
    item.text.toUpperCase().includes(filter.toUpperCase())
  );

  

  return (
    <div className="container">
      <h3 className='text-center pt-3 pb-5'>Order Page</h3>
      <div className="row m-3">
        {addresses.map((address, index) => (
          <div key={index} className="col-md-12 order_box">
            <div className="row my-3">
              <div className="col-md-1 d-flex align-items-center">
                <img width="48" height="48" src="https://img.icons8.com/fluency/48/product.png" alt="product" />
              </div>
              <div className="col-md-5 d-flex flex-column">
                <h6>Product: {address.cartItems.map(item => `${item.name} * ${item.quantity}`).join(', ')}</h6>
                <h6>Name: {address.firstName} {address.lastName}</h6>
                <h6>Address: {address.address}, {address.city}, {address.state} {address.pincode}</h6>
                <h5>Number: {address.phoneNumber}</h5>
                <h6>Order Date: {new Date(address.createdAt).toLocaleString()}</h6>
              </div>
              <div className="col-md-2 d-flex align-items-center">
                <div className='d-flex justify-content-center align-items-center'>
                  <p className='mb-0'>Item: </p>
                  <p className='mb-0 font-weight-bold'>&nbsp;{address.cartItems.reduce((total, item) => total + item.quantity, 0)}</p>
                </div>
              </div>
              <div className="col-md-2 d-flex align-items-center">
                <div className='d-flex justify-content-center align-items-center'>
                  <p className='mb-0 font-weight-bold'>&nbsp;₹ {address.cartItems.reduce((total, item) => total + (item.price * item.quantity), 0)}</p>
                </div>
              </div>
              <div className="col-md-2 d-flex align-items-center">
                <div className="col-md-6 py-3">
                  <select onChange={(event) => statusHandler(event, address._id)} value={address.status}>
                    <option value="Order Processing">Order Processing</option>
                    <option value="Out for delivery">Out for delivery</option>
                    <option value="Delivered">Delivered</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Adminorder;


