import React, { useEffect, useState } from 'react'
import Productdescriptionimg from '../assets/images/productdescription.png'
import { MdDelete } from "react-icons/md";
import '../css/Productlist.css'

const Productlist = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  const [allproducts, setAllProducts] = useState([]);

  const fetchInfo = async () => {
    await fetch(`${BACKEND_URL}/allproducts`)
      .then((res) => res.json())
      .then((data) => { setAllProducts(data) });
  }

  useEffect(() => {
    fetchInfo();
  }, [])


  const remove_product = async (id) => {
    await fetch(`${BACKEND_URL}/removeproduct`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: id })
    })
    await fetchInfo();
  }

  return (
    <>
      <div className="container productlist_d">
        <div className="row">
          <h3 className='text-center pt-3 pb-5'>All Products Lists</h3>
          <div className="col-md-2 d-flex justify-content-center align-items-center">
            <p>Products</p>
          </div>
          <div className="col-md-2 d-flex justify-content-center align-items-center">
            <p>Title</p>
          </div>
          <div className="col-md-2 d-flex justify-content-center align-items-center">
            <p>Old Price</p>
          </div>
          <div className="col-md-2 d-flex justify-content-center align-items-center">
            <p>New Price</p>
          </div>
          <div className="col-md-2 d-flex justify-content-center align-items-center">
            <p>Category</p>
          </div>
          <div className="col-md-2 d-flex justify-content-center align-items-center">
            <p>Remove</p>
          </div>

          <hr />

          {allproducts.length === 0 ? (
            <p>Loading...</p>
          ) : (
            allproducts.map((product, index) => {
              return (
                <>
                  <div className="col-md-12 pb-3" key={index}>
                    <div className="row">
                      <div className="col-md-2 d-flex justify-content-center align-items-center gap-3">
                        <img src={product.image} alt="" className='img-fluid cart_img' style={{ height: '6rem', width: '80%' }} />
                      </div>
                      <div className="col-md-2 d-flex justify-content-center align-items-center">
                        <p>{product.name}</p>
                      </div>
                      <div className="col-md-2 d-flex justify-content-center align-items-center">
                        <p>₹{product.old_price}</p>
                      </div>
                      <div className="col-md-2 d-flex justify-content-around align-items-center">
                        <p>₹{product.new_price}</p>
                      </div>
                      <div className="col-md-2 d-flex justify-content-around align-items-center">
                        <p>{product.category}</p>
                      </div>
                      <div className="col-md-2 d-flex justify-content-around align-items-center">
                        <MdDelete onClick={() => { remove_product(product.id) }} style={{ backgroundColor: 'red', color: 'white', fontSize: '20px', borderRadius: '3px' }} />
                      </div>
                    </div>
                  </div>
                  <hr />
                </>
              );
            })
          )}

        </div>
      </div>

      <div className="container productlist_m">
        <h3 className='text-center pt-3 pb-5'>All Products Lists</h3>

        {allproducts.length === 0 ? (
          <p>Loading...</p>
        ) : (
          allproducts.map((product, index) => {
            return (
              <>
                <div className="row" key={index}>
                  <div className="col-3">
                    <img src={product.image} alt="" className='img-fluid cart_img' style={{ height: '4rem', width:'100%' }} />
                  </div>
                  <div className="col-7">
                    <p><span className='product_list_heding'>Title     :</span> {product.name}</p>
                    <p><span className='product_list_heding'>Old Price :</span> {product.old_price}</p>
                    <p><span className='product_list_heding'>New Price :</span> {product.new_price}</p>
                    <p><span className='product_list_heding'>Category  :</span> {product.category}</p>
                  </div>
                  <div className="col-2 d-flex align-items-center justify-content-center">
                    <MdDelete onClick={() => { remove_product(product.id) }} style={{ backgroundColor: 'red', color: 'white', fontSize: '20px', borderRadius: '3px' }} />
                  </div>
                </div>
                <hr />

              </>
            );
          })
        )}


      </div>
    </>
  )
}

export default Productlist