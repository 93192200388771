// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Layout from './Components/Layout';
// import Addproduct from "./Components/Addproduct";
// import Productlist from "./Components/Productlist";

// function App() {
//   return (
//     <div className="App">
//       <BrowserRouter>
//         <Routes>
//           <Route path="/" element={<Layout />}>
//           <Route path="addproduct" element={<Addproduct />} />
//           <Route path="productlist" element={<Productlist />} />
//           </Route>
//         </Routes>
//       </BrowserRouter>
//     </div>
//   );
// }

// export default App;



import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './Components/Layout';
import Addproduct from './Components/Addproduct';
import Productlist from './Components/Productlist';
import LoginPopup from './Components/LoginPopup';
import Adminorder from './Components/Adminorder';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showPopup, setShowPopup] = useState(true);

  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
    setShowPopup(false);
  };

  return (
    <div className="App">
      <BrowserRouter>
        {!isAuthenticated && showPopup && (
          <LoginPopup onLoginSuccess={handleLoginSuccess} />
        )}
        {isAuthenticated && (
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route path="adminorder" element={<Adminorder />} />
              <Route path="addproduct" element={<Addproduct />} />
              <Route path="productlist" element={<Productlist />} />
            </Route>
          </Routes>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;

